@import '../../mixins';

.modal-successful {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(25);

  @include mediaBigDesktop {
    gap: big(25);
  }

  @include mediaLaptop {
    gap: rem(20);
  }

  &__icon {
    width: rem(60);
    height: rem(60);
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--system-states-success);
    border-radius: rem(1600);
    overflow: hidden;

    user-select: none;
    pointer-events: none;

    @include mediaBigDesktop {
      width: big(60);
      height: big(60);

      border-radius: big(1600);
    }

    & svg {
      width: rem(30);
      height: rem(30);

      fill: var(--bg-white);

      @include mediaBigDesktop {
        width: big(30);
        height: big(30);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }
  }

  &__title {
    text-align: center;
  }

  &__text {
    color: var(--text-dark-secondary);
    text-align: center;
  }
}
